/* Styles spécifiques à la Navbar */
.navbar-custom {
    background-color: #333; /* Changer la couleur de fond */
  }
  
  .navbar-brand-custom {
    font-weight: bold;
    font-size: 1.5rem; /* Taille personnalisée pour la marque */
  }
  
  .login-button {
    display: flex;
    align-items: center;
  }
  
  .login-icon {
    margin-right: 0.5rem; /* Espace entre l'icône et le texte */
  }
  